<template>
	<section class="contact-us section" >
			<div class="container" style="padding: 100px;">    
				<div class="row">
					<div class="col-xs-12 col-sm-12">
						<div class="contact-content">
							<div class="comm-title">

								<h3>{{Academy[0].Title}}</h3>
							</div>
							<v-row>
																	
							<span>
								<span v-html="Academy[0].HTMLTEXT"></span>
							</span> 
						</v-row>
						</div> <!-- /.contact-content -->
		
					</div> <!-- /.col- -->
	
				</div> <!-- /.row -->  
			</div> <!-- /.container -->
		</section> <!-- /.contact-us -->
		<!-- /Contact AREA END -->
</template>

<script>
	
export default {
  data(){
    return{
        id :null,
    }
  },
	computed : {
		Academy(){
      		return this.$store.state.Academy	 
		},
	},
	created(){
		this.id = this.$route.params.AcademyID
  	this.$store.dispatch('bindAcademy',this.id)
	},
	watch: {
    '$route.params.AcademyID'(newId, oldId) {
     this.id = newId
     
     this.$store.dispatch('bindAcademy',this.id)
    }
  },
}
</script>