<template>
	<div>
			<section class="page-head page-bg" :style="{ backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/websiteincubefzco.appspot.com/o/Others%2F11.png?alt=media&token=c9d0adc5-fece-4238-b55f-b56803e8ded5)' }">
			<div class="container">    
				<h3 class="page-heading"> </h3>
				<div class="justify-center">
          <center>
					
      </center>
        					
					<span> </span>
				</div>
			</div> <!-- /.container -->
		</section> <!-- /.page-head -->
		<!-- /Page Header AREA END -->

		<!-- Our Services AREA START -->
		<section class="our-service section">
			<div class="container">
			
								<h2 class="title"><center><span><b> Products</b></span></center></h2>

	<template>
  <v-card>

        <v-container fluid>
          <v-row>
            <v-col
              v-for="i in Products"
              :key="i.id"
              cols="12"
              md="3"
            >
                  <v-hover
  								close-delay="50"
  								v-slot="{ hover }">
              <v-card
    class="mx-auto"
    max-width="400"
    :class="{ 'on-hover': hover }"
    :elevation="hover ? 12 : 2">
              <v-img
                :src="i.Images[0]"
                contain
                aspect-ratio="1.5"
              ></v-img>
              <v-col>
                <div class="row">
   <div class="col-12 text-truncate">
     <H5 style="display: block;white-space: nowrap;width: 12em;overflow: hidden;text-overflow: ellipsis;" >{{i.ItemName}}</H5>
    </div>
  </div>
<v-divider
  inset
></v-divider>
   <v-row style="margin: 5px;">
   	    <v-btn
      class="ma-2"
      outlined
      color="blue-grey"
      :to="'/ProductDetails/'+ i.ItemName"
    >
      Read More
    </v-btn>
      <v-btn
        class="ma-2"
        text
        icon
        color="blue lighten-2"
      >
        <v-icon>mdi-file-pdf</v-icon>
      </v-btn>
   </v-row>
  </v-col>
            </v-card>
          </v-hover>
            </v-col>
          </v-row>
        </v-container>
  </v-card>
</template>
			</div><!-- container -->
		</section> <!-- /.our-service -->
		<!-- /Our Services AREA END -->
	</div>
</template>
<script>
  import { db  } from '@/db'
	import { mapMutations, mapState } from 'vuex'

export default {
	name: 'app',
	data () {
      return {
        dialog: false,
        categoryID : '',
        Categories :[],
        Backimage : ''
      }
    },
    created(){
      
      var context = this
      this.categoryID = this.$route.params.CategoryID
      var arr = this.$store.state.Categories;
      let result = arr.filter(obj => {
        return obj.C_Name ===  this.categoryID
      })
      this.$store.dispatch('bindProductByType' , result[0].id).then(function () {
            context.getimage()
        });
    },
    computed:{
      Products(){
        return this.$store.state.Product
      },
      
    },
    watch: {
    '$route.params.CategoryID'(newId, oldId) {
     this.categoryID  = newId
     
      var context = this
      var arr = this.$store.state.Categories;
      let result = arr.filter(obj => {
        return obj.C_Name ===  this.categoryID
      })

      
      this.$store.state.Categories.size
     this.$store.dispatch('bindProductByType' , result[0].id).then(function () {
            context.getimage()
      });
    }
  },
    methods :{
      getimage(){
        
        if (this.Products[0].D_ID === 'SefqWuqeOdYR7h5u9DIf')
         this.Backimage = 'https://firebasestorage.googleapis.com/v0/b/incubewebsite.appspot.com/o/PartnerBackGround%2Fdatalogic.png?alt=media&token=b55205a0-74f7-42ca-9a3f-f07b6c0b3238'
       else
          this.Backimage = 'https://firebasestorage.googleapis.com/v0/b/incubewebsite.appspot.com/o/Distribution%2Fprintek.png?alt=media&token=ff304551-a165-4083-8637-17bf15bf9766'

      }
    }

}
</script>

<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>