<template>
  <div>
    <center>
      <div class="section product-description p-t-none">

        <div class="comment" style="width: 80%;margin-top: 10%;">



          <h1 class="text-left">
            <center><span><b>Events</b></span></center>
          </h1>

          <div v-if="!Events.length">


            <v-img max-height="150" max-width="250"
              src="https://firebasestorage.googleapis.com/v0/b/incubewebsite.appspot.com/o/Others%2Fempty.png?alt=media&token=9cf2fc13-4302-415e-a486-31fd3df0ea3e"></v-img>

            <p style="font-size: x-large;">Sorry Aren't Found Any New Events , Please Check Again Later</p>
          </div>
          <div v-else>
            <template>
              <v-card margin="5px" class="mx-auto" style="width: 100%;margin: 5px;" v-for="n in Events" v-bind:key="n.id">
                <v-list-item>
                  <v-row>
                    <v-col cols="12" sm="4" style="width: 50px;">
                      <!-- <div v-if="getlength(n.Media.length) > 1">
                        <agile :initial-slide="0">

                          <div v-for="media in n.Media" :key="media.id">
                            <div v-if="media.Type === 'Video'">
                              <iframe loading="auto" height="300" :src="media.src" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                            </div>
                            <div v-else>
                              <img :src="media.src" :alt="media.src">
                            </div>
                          </div>
                        </agile>
                      </div> -->
                      <div >
                        <img :src="n.Media[0].src">
                      </div>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-list-item-content>
                        <h2 class="text-left">{{ n.EventTitle }}</h2>

                        <h4 class="text-left" style="color: black;">
                          {{ n.EventDesc }}
                        </h4>


                      </v-list-item-content>
                    </v-col>
                  </v-row>
                </v-list-item>


                <v-card-actions>
                  <v-btn class="ma-2" color="blue" outlined style="margin: 5px;">
                    {{ n.date }}
                    <v-icon color="blue" left>
                      mdi-calendar
                    </v-icon>
                  </v-btn>
                  <v-btn class="ma-2" color="blue" outlined style="margin: 25px;">
                    {{ n.location }}
                    <v-icon color="blue" left>
                      mdi-google-maps
                    </v-icon>
                  </v-btn>

                  <v-btn color="blue" outlined :href="n.Linkedin" style="margin: 25px;">
                    Linkedin
                    <v-icon color="blue" left>
                      mdi-linkedin
                    </v-icon>
                  </v-btn>

                </v-card-actions>
              </v-card>
            </template>

          </div>

        </div>
      </div>
    </center>
  </div>
</template>

<script>

export default {
  name: 'app',
  data() {
    return {
      dialog: false,
      partnerID: '',
    }
  },
  created() {
    this.$store.dispatch('bindEvents');
  },
  computed: {
    Events() {
      return this.$store.state.Events
    },
  },
  methods: {
    getlength(list) {
      return list.length
    },
    format_date: function (value) {

      let myDate = new Date(value.seconds * 1000)
      var now = new Date()


      if (!(myDate.getDate() == now.getDate() && myDate.getMonth() == now.getMonth()
        && myDate.getFullYear() == now.getFullYear())) {
        let options = {
          weekday: "long", year: "numeric", month: "short", day: "numeric"
        };
        return myDate.toLocaleDateString("en-us", options)
      }
      else {
        let options = {
          hour: "2-digit", minute: "2-digit"
        };
        return ("Today , " + myDate.toLocaleTimeString("en-us", options))
      }



    },
  }
}
</script>
