import Vue from 'vue'
import Vuex from 'vuex'
import { firestoreAction } from 'vuexfire'
import { db ,fb } from '@/db'
import { vuexfireMutations } from 'vuexfire'
Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    DialogMsg :'',
    DialogState : false,
    user : null,
    userType : null,
    PortalFiles : null,
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    Item:[],
    Partner :[],
    Products:[],
    SatiProducts:[],
    Users : [],
    OurWork:[],
    Distribution : [] ,
    CareerType : [],
    AllDistribution : [] ,
    Invoice:[],
    Product :[],
    Careers:[],
    Events :[],
    Categories :[],
    Academy :[],
    Info :[],
    academies : [],
    Solutions : [],
    NewsLetters : [],
    NewsLettersBanr : [],
    Requests :[],
    EventRequests :[],
  },
  mutations: {
     ...vuexfireMutations,
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
   Set_User(state , user) {
    state.user = user
  },
  Set_UserType(state , type){
    state.userType = type
  },
  Set_DialogState(state , DialogState){
    state.DialogState = DialogState
  },
  Set_DialogMsg(state , Msg){
    state.DialogMsg = Msg
  },
  logout(state) {
    state.user = null
    },
  },

  getters:{
        isAuthentication(){
          return user
        },

  },

  actions: {
    formatDate() {
          
          var d = new Date(),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();
      
          if (month.length < 2) 
              month = '0' + month;
          if (day.length < 2) 
              day = '0' + day;
      
          return [year, month, day].join('-');
      },
      getUser(){
        return user
      },

      setAuthUser(_, {username }){
        commit('setAuthUser1',username)
      },
        signout({commit}){
          return firebase.auth().signOut()
            .then(_ => commit('setAuthUser',null))
      },
      binditem: firestoreAction(({ bindFirestoreRef } ,  id ) => {
        
        return bindFirestoreRef('Item', db.collection('Product').where('ItemName' , '==',id ))
      }),   
      
      bindPartner: firestoreAction(({ bindFirestoreRef } ) => {
      return bindFirestoreRef('Partner', db.collection('Partner'))
      
      }),
      bindOurWork: firestoreAction(({ bindFirestoreRef } ) => {
      return bindFirestoreRef('OurWork', db.collection('OurWork'))
      }),   
      bindProducts: firestoreAction(({ bindFirestoreRef } ) => {
      return bindFirestoreRef('Products', db.collection('Products').orderBy('P_Name', 'desc'))
      }),  
      bindProductByType: firestoreAction(({ bindFirestoreRef } ,SectionID) => {
        
      return bindFirestoreRef('Product', db.collection('Product').where('type','==',SectionID))
      }),  
      bindProductsSatisfied: firestoreAction(({ bindFirestoreRef } , ProductID ) => {
        var query =  db.collection('Solutions').where('SolutionTitle', '==',ProductID)
        query = query.where("InActive", "==", false)
      return bindFirestoreRef('SatiProducts', query)
      }),  
      binduser: firestoreAction(({ bindFirestoreRef } ) => {
      return bindFirestoreRef('Users', db.collection('Users'))
      }),   
      bindDistribution: firestoreAction(({ bindFirestoreRef } ,  DistID ) => {
        debugger
      return bindFirestoreRef('Distribution', db.collection('Distribution').where('D_Name' , '==', DistID ))
      }),   
      bindAllDistribution: firestoreAction(({ bindFirestoreRef } ,  DistID ) => {
      return bindFirestoreRef('AllDistribution', db.collection('Distribution').orderBy('Order'))
      }), 
      bindInvoice: firestoreAction(({ bindFirestoreRef } ) => {
      return bindFirestoreRef('Invoice', db.collection('Purchase_Invoice'))
      }), 
      bindProduct: firestoreAction(({ bindFirestoreRef } ) => {
      return bindFirestoreRef('Product', db.collection('Product'))
      }), 
      bindportal: firestoreAction(({ bindFirestoreRef } ) => {
        return bindFirestoreRef('PortalFiles', db.collection('Portal'))
      }), 
      bindProductPartner: firestoreAction(({ bindFirestoreRef } , PartnerID ) => {
        var context = this;
      return bindFirestoreRef('Product', db.collection('Product').where('D_ID' , '==', PartnerID ))
      }), 
      bindCareerType  : firestoreAction(({ bindFirestoreRef } ) => {
      return bindFirestoreRef('CareerType', db.collection('CareerType'))
      }), 
      bindCareers  : firestoreAction(({ bindFirestoreRef } ) => {
      return bindFirestoreRef('Careers', db.collection('Career'))
      }), 
      bindSatiCareers  : firestoreAction(({ bindFirestoreRef } , CareerID) => {
      return bindFirestoreRef('Careers', db.collection('Career').where('ID', '==',CareerID))
      }), 
      bindEvents  : firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('Events', db.collection('Events'))
      }), 
      bindHomeEvents  : firestoreAction(({ bindFirestoreRef }) => {
        
        return bindFirestoreRef('Events', db.collection('Events')        
        .where("date", ">",  new Date().toISOString().slice(0, 10))
        .orderBy("date", "desc").limit(3))

        }),
      bindCategories  : firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('Categories', db.collection('Categories').orderBy('Order'))
      }), 
      bindAcademy : firestoreAction(({ bindFirestoreRef } , AcademyID) => {
      return bindFirestoreRef('Academy', db.collection('IncubeAcademy').where('ID' , '==', AcademyID ))
      }), 
      bindNewsLetters : firestoreAction(({ bindFirestoreRef } , NewsID) => {
      return bindFirestoreRef('NewsLetters', db.collection('Newsletter').where('ID' , '==', NewsID ))
      }), 
      bindNewsLettersDash : firestoreAction(({ bindFirestoreRef } ) => {
      return bindFirestoreRef('NewsLetters', db.collection('Newsletter'))
      }), 
      bindNewsLettersBanar : firestoreAction(({ bindFirestoreRef } , NewsID) => {
      return bindFirestoreRef('NewsLettersBanr', db.collection('Newsletter').orderBy("Date", "desc").limit(3))
      }), 
      bindAllNewsLetters : firestoreAction(({ bindFirestoreRef } , NewsID) => {
      return bindFirestoreRef('NewsLettersBanr', db.collection('Newsletter').orderBy("Date", "desc"))
      }), 
      bindacademies : firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('academies', db.collection('IncubeAcademy'))
      }), 
      bindSolutions : firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('Solutions', db.collection('Solutions').where("InActive", "==", false).orderBy('Order'))
      }), 
      bindInfo : firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('Info', db.collection('Information'))
      }), 
      bindRequests : firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef('Requests', db.collection('JobRequest').orderBy("Date", "desc"))
      }), 
      bindeventRequests : firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef('EventRequests', db.collection('events').doc('Summit2023').collection('Users').orderBy("Fullname", "desc"))
        }), 
  }
    
})
