<template>
	<div style="background: white;">
		<div :style="{'background-size': 'cover' , 'background-image': `url(${Product[0].BackTitle})`  }">
			<section class="page-head page-bg"  style="background: rgb(0 0 0 / 45%);">
				<center>
				<v-img 
				width =650
				height = 150
				:src="Product[0].Logo"></v-img>
				</center>
			<div class="container" >    
				<h3 class="page-heading"> </h3>
				<div class="sub-title">
					<a href="#"><span></span></a>
					
					<span> </span>
				</div>
			</div> <!-- /.container -->
		</section> <!-- /.page-head -->
		</div>
		<!-- /Page Header AREA END -->


		<!-- Our Services AREA START -->
		<section class="our-service section">

			<div class="container">
				<center>
				<h6 class="title" style="font-weight: bold;font-size: 2em;">
					
					{{Product[0].SolutionTitle}}
				</h6></center>


				<div class="row">
					<div class="col-sm-8 col-xs-12 col-sm-offset-2 col-xs-offset-0">
							<p style="color: #26476c;font-weight: bold;">
						{{Product[0].Subtitle}}
					</p>
					<ul>
					<p>
						{{Product[0].SolutionDesc}}
					</p>

					</ul>

<div v-for = "(ImageFlow,index) in Product[0].ImagesFlow"
										 :key = "index+ImageFlow" >
										 <v-img :src="ImageFlow"></v-img>
										</div>

					  <v-btn
                    text
                    outlined
                    color="blue lighten-2"
                    :href="`${Product[0].PDFURL}`"
                    target="_blank"
                  >
      <v-icon
      style="margin: 10px;"
      left>mdi-file-pdf</v-icon>
      Download Datasheet
        
      </v-btn>
					</div>
				</div> <!-- /.row -->

								<h2 class="title"><center><span><b> Business Modules</b></span></center></h2>

				<div class="row">
					<!-- Single Service -->
					<div class="col-md-3 col-sm-6 col-xs-9" 
					v-for = "(Product, index) in Product[0].BusinessModel"
					:key = "index">
						<div class="single-services">
							<div class="icon"><img class="flaticon-img icon-large icon-large" 
								:src="Product.Image" alt="Icon"></div>
							<div class="icon two"><img class="flaticon-img icon-large icon-large" 
								:src="Product.Image" alt="Icon"></div>
							<h4>{{Product.Desc}}</h4>
							<p></p>
						</div>
					</div>
					<!--/ End Single Service -->
				</div> <!-- /.row -->
			</div><!-- container -->
		</section> <!-- /.our-service -->
		<!-- /Our Services AREA END -->

		<!-- Working AREA START -->
		<section class="working">
			<div class="full-width-sec">
				
			</div> <!-- /.full-width-sec full-width-sec-2nd -->

			<div class="full-width-sec full-width-sec-2nd">
				<div class="container">    
					<v-row >
						<v-col
						cols="12"
						md="8">
							<div class="get-us">
								<div class="get-head">
									
									<div class="get-title">
										<h4>Why Should You use 	{{Product[0].SolutionTitle}}?</h4>
									</div>
								</div> <!-- /.get-head -->
								<div class="slider service-slider-content-2nd">
									<div class="get-content">
										<p></p>
									</div>
									<div class="get-content">
										<p></p>
									</div>
									<div class="get-content">
										<p></p>
									</div>
									<div class="get-content">
										<p></p>
									</div>
									<div class="get-content">
										<p></p>
									</div>
									<div class="get-content">
										<p></p>
									</div>

								</div> <!-- /.service-slider-content -->
								<div class="row get-all-items">
									<ul class="get-services">
										 <li
										 v-for = "(Product, index) in Product[0].WhyUse"
										 :key = "index"
										 >{{Product}}</li>
										 
									 </ul> 
								</div>
							</div> <!-- /.get-us -->					
						</v-col> <!-- /.col- -->
						<v-col
							md="4"
							cols="12">

									<v-img   height="350" width="350" contain :src="Product[0].WhyUseImage" >
									</v-img>
						</v-col> <!-- /.col- -->
					</v-row> <!-- /.row -->  
				</div> <!-- /.container -->
			</div> <!-- /.full-width-sec.full-width-sec-2nd -->
		</section> <!-- /.working -->
		<!--  Working AREA END -->
	</div>
</template>
<script>

	import { mapMutations, mapState } from 'vuex'

export default {
	name: 'app',
	data () {
      return {
        dialog: false,
        ProductID: '',
      }
    },
    created(){
		var context = this;
		this.ProductID = this.$route.params.ProductID
		this.$store.dispatch('bindProductsSatisfied',this.ProductID).then(() => {
        if (context.Product.length == 0)
          window.location.href = "/404-page";
    });
    },
	computed:{
		Product(){
        return this.$store.state.SatiProducts
      },
	},
	 watch: {
    '$route.params.ProductID'(newId, oldId) {
     this.ProductID = newId
     this.$store.dispatch('bindProductsSatisfied',this.ProductID)
    }
  },

}
</script>