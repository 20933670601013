

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { firestorePlugin } from 'vuefire'
import * as VueGoogleMaps from "vue2-google-maps";
import * as Validate from 'vee-validate'

import { VueEditor } from "vue2-editor";

import firebase from 'firebase'
import 'firebase/auth'
import CoreuiVue from '@coreui/vue';

import VueAgile from 'vue-agile'
import VueSlickCarousel from 'vue-slick-carousel'
import FlagIcon from 'vue-flag-icon'

import VueCarousel from 'vue-carousel';
import loadScript from 'vue-plugin-load-script';




Vue.use(loadScript);
Vue.use(VueCarousel);
Vue.use(FlagIcon);
Vue.use(VueAgile)
Vue.use(CoreuiVue);
Vue.use(firestorePlugin)
Vue.use(VueSlickCarousel)

Vue.use(Validate)
Vue.use(VueEditor)
Vue.prototype.$UserAuth = null

Vue.config.productionTip = false

Vue.loadScript('./assets/js/jquery-3.2.1.min.js')
Vue.loadScript('./assets/js/jquery-migrate-3.0.0.min.js')
Vue.loadScript('./assets/js/bootstrap.min.js')
Vue.loadScript('./assets/contact-script/validator.js')
Vue.loadScript('./assets/contact-script/contact.js')
Vue.loadScript('./assets/js/jquery.slicknav.js')
Vue.loadScript('./assets/js/jquery.mobile.custom.min.js')
Vue.loadScript('./assets/js/isotope.pkgd.min.js')
Vue.loadScript('./assets/js/jquery.counterup.min.js')
Vue.loadScript('./assets/js/waypoints.min.js')
Vue.loadScript('./assets/js/jquery.lineProgressbar.js')
Vue.loadScript('./assets/js/slick.min.js')
Vue.loadScript('./assets/js/particles.min.js')
Vue.loadScript('./assets/js/particles-config.js')
Vue.loadScript('./assets/js/custom.js')

Vue.use(VueGoogleMaps, {
  load: { key: "AIzaSyDx7GyCznFoxl0kDKO7H2-UW3KcXCMuuGE" }
});
let app;


 if (!app) {
    app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
}
