<template>
	    <footer class='footer' v-if="$route.meta.onlyAuthUser !== true">
      <div class='footer-main cbiz-bg section'>
        <div class='container'>    
          <div class='row'>
            <div class='widgets'>
              <div class='col-xs-12 col-sm-12 col-md-4'>
                <div class='widget logo'>
                  <div class='wi-title'>
                  </div>
                  <div class='wi-content'>
                    <p>INCUBE provides supply chain mobility solutions focusing on trainability and food safety, covering track , trace , planning , route optimization , warehouse management , sales and distribution ( cash van )</p>
                    <ul class='wi-list'>
                      <li>
                        <a href='https://goo.gl/maps/2e6rWya28mkM3AgX7'><i aria-hidden='true' class='fa fa-map-marker'/>Get Directions</a>
                      </li>
                      <li>
                        <a href='mailto:marketing@e-incube.com'><i class='fa fa-envelope-o'/>marketing@e-incube.com</a>
                      </li>
                      <li>
                        <a href='tel:+97148876675'><i class='fa fa-phone'/>+97148876675 </a>
                      </li>

                    </ul>
                  </div>
                </div> <!-- /.widget -->
              </div> <!-- /.col- -->
              <div class='col-xs-12 col-sm-12 col-md-4'>
                <div class='widget'>
                  <div class='wi-title'>
                     <h5>Popular Tags</h5>
                  </div>
                  <div class='wi-content wi-tags'>
                    <ul>
                      <li><a >Hardware</a></li>
                      <li><a >Track And Trace</a></li>
                      <li><a >Solutions</a></li>
                      <li><a >Mobile Printer</a></li>
                      <li><a >Desktop Printer</a></li>
                    </ul>
                  </div> <!-- /.wi-content -->
                </div> <!-- /.widget -->
                <div class='widget'>
                  <div class='wi-title'>
                     <h5>Follow Us</h5>
                  </div>
                  <div class='wi-content wi-social'>
                    <ul>
                      <li>
                        <a href='https://www.facebook.com/InCubeCo' target="_blank">
                          <i class='fa normal-show fa-facebook'/>
                          <i class='fa hover-show fa-facebook'/>
                        </a>
                      </li>
                      <li>
                        <a href='https://www.linkedin.com/company/incubefzco' target="_blank">
                          <i class='fa normal-show fa-linkedin'/>
                          <i class='fa hover-show fa-linkedin'/>
                        </a>
                      </li>
                                            <li>
                        <a href='https://wa.me/message/FDX2XP3QXPXSN1' target="_blank">
                          <i class='fa normal-show fa-whatsapp'/>
                          <i class='fa hover-show fa-whatsapp'/>
                        </a>
                      </li>
                                            <li>
                        <a href='https://www.youtube.com/channel/UCwB2XHEcS4I-3JLm9fwEC6A' target="_blank">
                          <i class='fa normal-show fa-youtube'/>
                          <i class='fa hover-show fa-youtube'/>
                        </a>
                      </li>
                    </ul>
                  </div> <!-- /.wi-content -->
                </div> <!-- /.widget -->
              </div> <!-- /.col- -->
              <div class='col-xs-12 col-sm-12 col-md-4'>
                                  <div class='wi-title'>
                     <h5>Our Visitor</h5>
                  </div>
<form ref="myform"/>

              </div>
            </div> <!-- /.widgets -->
          </div> <!-- /.row -->  
        </div> <!-- /.container -->
      </div> <!-- /.footer-main -->
      <div class='copyright'>
        <div class='container'>
          INCUBE FZCO | {{GetCurrentYear()}} All rights reserved.  
          <router-link
                      :to="'Privacy-Policy'">
                      Privacy Policy
                    </router-link>
        </div>
      </div> <!-- /.copyright -->
    </footer> <!-- /.footer -->
</template>

<script >
  export default {
 mounted() {
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', '//rf.revolvermaps.com/0/0/8.js?i=5t2gd8ahp8g&amp;m=0&amp;c=9d8b63&amp;cr1=ff0000&amp;f=arial&amp;l=33&amp;as=100')
    this.$refs.myform.appendChild(externalScript)
  },
  methods :{
    GetCurrentYear(){
      return new Date().getFullYear();
    }
  }
  }
</script>