<template>
  <div style="background: white;">
    <!-- HEADER -->
    <section class="hero is-black" style="height: 200px;">
      <div class="hero-body">
        <div class="hero-img">
        </div>
        <div class="container">
          <div class="columns">
            <div class="column is-9" style="width: 60%;">

              <div class="user-tile">



              </div>
            </div>
            <div class="column is-3" style="width: 40%;">
              <div class="column-right" style="width: 40%;">

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- HEADER -->
    <div class="container">
      <v-row>
        <v-col md="4" lg="4" sm="4" xl="4">
          <div>
            <div class="border-in">
              <div class="comm-title">
                <h4>Join Us To Be An InCuber</h4>
              </div>
              <div>
                <div class="row">
                  <div class="col-xs-12 col-sm-12">
                    <v-text-field label="Your Name" name="from_name" v-model="Job.from_name" outlined></v-text-field>
                  </div> <!-- /.col- -->
                  <div class="col-xs-12 col-sm-12">

                    <v-text-field label="Your Email" name="from_email" v-model="Job.from_email" outlined></v-text-field>
                  </div> <!-- /.col- -->
                  <div class="col-xs-12 col-sm-12">
                    <v-text-field label="Your Phone #" v-model="Job.from_Phone" name="from_Phone" outlined></v-text-field>
                  </div> <!-- /.col- -->

                  <div class="col-xs-12 col-sm-12">
                    <v-text-field v-model="Job.resumeURL" name="resumeURL" outlined v-show='false'></v-text-field>
                  </div> <!-- /.col- -->




                  <div class="col-xs-12 col-sm-12">
                    <v-file-input label="Upload Resume PDF" v-model="Job.resume" outlined accept="application/pdf"
                      dense></v-file-input>
                  </div> <!-- /.col- -->

                  <v-btn @click="sendEmail">Send
                    <v-icon right dark>mdi-cloud-upload</v-icon>
                  </v-btn>
                  <div class="messages"></div>
                </div> <!-- /.row -->
              </div> <!-- /.contact-form -->
            </div>
          </div> <!-- /.col- -->
        </v-col>
        <v-col md="8" lg="8" sm="8" xl="8">
          <div>
            <div class="section">
              <div class="more-details">
                <div class="more-details-title">
                  Career Details
                </div>
                <div>


                  <div style="padding: 10px;">


                    <v-row>
                      <h4>Job Title : </h4>
                      <p style="font-size: large;font-weight: bold;">
                        {{ Career[0].JobName }}
                      </p>
                    </v-row>

                    <v-row class="more-details-item">
                      <h4>Work Time : </h4>
                      <p style="font-size: large;font-weight: bold;">
                        {{ Career[0].WorkTime }}
                      </p>

                    </v-row>


                    <v-row class="more-details-item">
                      <h4>Location:</h4>
                      <p style="font-size: large;font-weight: bold;">{{ Career[0].location }}</p>

                    </v-row>


                    <v-row class="more-details-item">
                      <h4>Experince : </h4>
                      <p style="font-size: large;font-weight: bold;">
                        {{ Career[0].Experince }}
                      </p>

                    </v-row>



                  </div>

                </div>
              </div>
            </div>
            <div class="section product-description p-t-none">
              <div class="product-description-title">Description</div>
              <div class="product-description-details" style="width: 80%;">
                <p style="color: black;">{{ Career[0].description }}</p>
              </div>


            </div>
          </div>
        </v-col>



      </v-row>
    </div>
  </div>
</template>

<script >
import { db, fb } from '@/db'
import emailjs from 'emailjs-com';

export default {
  data() {
    return {
      form: {
        id: null,
        categoryName: ''
      },
      Job: {
        from_name: '',
        from_email: '',
        from_Phone: '',
        resume: '',
        job_title: '',
        Date: new Date(),
      }

    }
  },
  computed: {
    Career() {
      
      return this.$store.state.Careers
    },
  },
  created() {
    var context = this;
    this.form.id = this.$route.params.CareerID
    this.$store.dispatch('bindSatiCareers', this.form.id).then(() => {
      
      if (context.Career.length == 0)
        window.location.href = "/404-page";
    });


  },
  watch: {
    '$route.params.CareerID'(newId, oldId) {
      this.form.id = newId
      this.$store.dispatch('bindSatiCareers', this.form.id)
    }
  },
  methods: {
    sendEmail() {
      
      var context = this;
      let file = this.Job.resume;
      this.Job.job_title = this.Career[0].JobName;
      var uuid = require("uuid");
      var storageRef1 = fb.storage().ref('CV/' + this.form.id + '/' + uuid.v4() + '.pdf')
      let UploadTask1 = storageRef1.put(file);
      UploadTask1.on('state_changed', async function (snapshot) {
      }, function (error) {

      }, function () {
        UploadTask1.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          
          context.Job.resume = downloadURL;
          db.collection('JobRequest')
            .add(context.Job).then(docRef => {
              alert('Your request has been sent, we will contact you shortly .')
              if (context.Career.sendByEmail) {
                context.Job.Email = context.Career.Email
                emailjs.send('service_h3evmpm', 'template_w6cczse', context.Job, 'user_gKUe58eKzR9LXruQfrTrR')
                  .then((result) => {
                    console.log('SUCCESS!', result.status, result.text);
                  }, (error) => {
                    console.log('FAILED...', error);
                  });
              }
              context.Job = ''
            });



        })
      })

    },
    onPickFile() {
      this.$refs.fileinput.click();
    },
  }
}
</script>

<style scoped lang="scss">
// CARD
.card {
  z-index: 9999;
  min-width: 330px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 1px 1px rgba(20, 23, 28, .1), 0 3px 1px 0 rgba(20, 23, 28, .1);

  .title {
    color: gray;
  }

  .subtitle {
    color: gray;
  }

  .product-features {
    font-size: 17px;
  }

  .main-price {
    font-size: 17px;
    color: #7d7d7d;
    text-decoration: line-through;
  }
}

// CARD
// WHOLE HEADER
.hero-body {
  position: relative;
}

.hero-img {
  opacity: 0.8;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  filter: sepia(.1) grayscale(.1) saturate(.8);
}

.column-right {
  position: absolute;
}

@media screen and (max-width: 770px) {
  .column-right {
    position: inherit;
  }
}

.user-avatar {
  display: inline-block;
}

.is-black {
  background-color: black;
  background: linear-gradient(#29303B, #29303B, #29303B);
}

.title {
  font-weight: bold;
  font-size: 45px;
}

.subtitle {
  /*font-weight: bold;*/
  font-size: 25px;
}

.author-name {
  font-size: 20px;
  font-weight: bold;
}

.rate {
  font-size: 29px;
  font-style: italic;
}

// WHOLE HEADER END
.more-details {
  background-color: #f9f9f9;
  border: 1px solid #dedfe0;
  padding: 10px 15px;

  &-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &-items {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 19px;
    width: 45%;
  }
}

.comment {
  background-color: #f9f9f9;
  border: 1px solid #dedfe0;
  padding: 10px 15px;

  &-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &-items {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 19px;
    width: 45%;
  }
}

.product-description {
  padding-top: 0;

  &-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &-details {
    font-size: 18px;

    ul {
      list-style: disc;
      margin-left: 20px;
    }

    ol {
      margin-left: 20px;
    }

    strong {
      font-size: 20px;
    }

    p {
      min-height: 30px;
    }
  }
}

// USERS
.user-tile {
  display: flex;

  &-author {
    align-self: center;
    margin-left: 10px;

    &-name {
      font-size: 17px;
    }
  }

  .date {
    font-size: 14px;
  }
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 50%;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 80px;
}

.agile__nav-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.agile__nav-button--prev {
  left: 0;
}

.agile__nav-button--next {
  right: 0;
}

.agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.agile__dot {
  margin: 0 10px;
}

.agile__dot button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 10px;
}

.agile__dot--current button,
.agile__dot:hover button {
  background-color: #fff;
}

.slide {
  display: block;
  height: 380px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
</style> 