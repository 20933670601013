
<template>
  <v-app>
<div>
      <v-card style="margin: 150px;">
      <div class="container">

        <center>
          <h3  v-if="type === 'Reseller' || type === 'Partner' " >Become A {{type}}</h3>
           <h3  v-if="type === 'Demo'" >Request</h3>
        </center>
        <v-row>
		 <v-col
        cols="12"
        sm="6"
      >
      <v-text-field
        label="First Name"
        class="purple-input"
        v-model="Fname"
        :rules="[() => !!Fname || 'This field is required']"
        required
        outlined
        dense
      />
  </v-col>

  	 <v-col
        cols="12"
        sm="6"
      >
      <v-text-field
        label="Last Name"
        v-model="Lname"
        :rules="[() => !!Lname || 'This field is required']"
        class="purple-input"
        required
        outlined
        dense
      />
  </v-col>
</v-row>
<v-row>
  	 <v-col
        cols="12"
        sm="4"
      >
      <v-text-field
        label="Email"
        v-model="Email"
        class="purple-input"
        :rules="[() => !!Email || 'This field is required']"
        required
        outlined
        dense
      />
  </v-col>
  	 <v-col
        cols="12"
        sm="4"
      >
      <v-text-field
        label="Phone"
        v-model="Phone"
        class="purple-input"
        :rules="[() => !!Phone || 'This field is required']"
        required
        outlined
        dense
      />
  </v-col>

       <v-col
        cols="12"
        sm="4"
      >
      <v-text-field
        label="Company"
        v-model="Company"
        class="purple-input"
        :rules="[() => !!Company || 'This field is required']"
        outlined
        dense
      />
  </v-col>

</v-row>
<v-row style="margin-bottom: 50px;">
  	 <v-col
        cols="12"
        sm="6">

<v-select
          :items="countryList"
          label="Country"
           v-model="Country"
          :rules="[() => !!Country || 'This field is required']"
          outlined
          dense
        ></v-select>
</v-col>

<v-col  v-if="type === 'Demo'"     
    cols="12"
    md="3">
<v-select
          :items="items"
          label="Product"
          v-model="Product"
          outlined
          dense
        ></v-select>
</v-col>


<v-col  v-if="type === 'Demo'"     
    cols="12"
    md="3">
 <v-text-field
        label="QTY"
        v-model="Qty"
        class="purple-input"
        :rules="numberRule"
        outlined
        dense
        type="number"
      />
    </v-col>
</v-row>
    <v-btn
      class="ma-2"
      outlined
      color="indigo"
      @click="submit"
    >
     Submit
    </v-btn>
</div>

</v-card>
</div>
</v-app>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'app',
  data () {
  return {
    type : '',
    items :[
    'Printing Solutions',
    'Scanning Solutions',
    'Supply Chain Solutions',
    'Blockchain',
    'Solutions In A Cube'],
    select: 'Not',
  countryList : [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands"],
  formHasErrors: false,
  Fname: '',
  Lname: '',
  Email: '',
  Country: '',
  Company: '',
  Phone: '',
  Product: '',
  Qty: '',
  numberRule: [v => (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) || 'Number has to be between 0 and 999']

      }
    },
  created(){
  this.type = this.$route.params.Type
  },
  computed : {
      form () {
        return {
          Fname: this.Fname,
          Lname: this.Lname,
          Email: this.Email,
          Country: this.Country,
          Company: this.Company,
          Phone: this.Phone,
          Product: this.Product,
          Qty : this.Qty
        }
      },
  },
   watch: {
    '$route.params.Type'(newId, oldId) {
      this.type = newId
    }
  },
  methods :{
  submit () {
    
    if (!this.checkValue()){
      alert('Please Fill All Of Required Values !!')
      return
    }
        this.formHasErrors = false
        var context = this
        this.form.type = context.type;
        this.form.Product = context.Product  ;
        emailjs.send('service_h3evmpm', 'template_t0p0s0t', context.form, 'user_gKUe58eKzR9LXruQfrTrR')
                .then((result) => {
                    console.log('SUCCESS!', result.status, result.text);
                    alert('Your request has been sent, we will contact you shortly .')

                    context.Product ='';
                    context.Fname = '';
                    context.Lname= '';
                    context.Email = '';
                    context.Company = '';
                    context.Country = '';
                    context.Phone = '';
                    context.Qty = '';
                }, (error) => {
                    console.log('FAILED...', error);
                });

        // Object.keys(this.form).forEach(f => {
        //   if (!this.form[f]) this.formHasErrors = true

        //   context.$refs[f].validate(true)
        // })
      },
  checkValue(){
    
    if (!this.Fname || this.Fname ==='')
      return false;

     if (!this.Lname || this.Lname ==='')
      return false;

     if (!this.Company || this.Company ==='')
      return false;

     if (!this.Phone || this.Phone ==='')
      return false;

     if ((!this.Product|| this.Product==='') && this.type === 'Demo') 
      return false;

     if ((!this.Qty|| this.Qty==='') && this.type === 'Demo')
      return false;

    return true;
  }
  }
}
</script>

<style type="text/css">
.box {
  position: absolute;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.box select {
  background-color: #26476c;
  color: white;
  padding: 12px;
  weight: 100% ;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}

.box::before {
  content: "\f13a";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
   weight: 100% ;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.box:hover::before {
   weight: 100% ;
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
   weight: 100% ;
  padding: 0px;
}</style>