<template>
	<section class="contact-us section" >
			<div class="container" style="padding: 100px;">    
				<div class="row">
					<div class="col-xs-12 col-sm-6">
						<div class="contact-content">
							<div class="comm-title">
								<h3>Contact Us</h3>
							</div>
							<p>Thank you for your interest in INCUBE’s innovative supply chain solutions, products and services. INCUBE strives to provide the best service possible to all visitors as we operate in an industry built on trust. From basic questions to demo inquiries, we're here to help! Get in touch with us today!
							</p> 
						</div> <!-- /.contact-content -->
						<div class="lets-contact">
							 
							 <v-tabs
      v-model="tab"
      background-color="transparent"
      color="basil"
      grow
    >
      <v-tab
        v-for="item in items"
        :key="item.Contry"
      >
       <img :src='item.Icon' :alt='item.Contry' :title='item.Contry' width="25" height="25" style="margin: 5px;"/> <p>{{  item.Contry}} </p>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        :key="item.Contry"
      >
        <v-card
          color="basil"
          flat
        >


<ul class="contact-list">
								<li>
									<a href="#"><i class="fa fa-paper-plane-o"></i>{{item.Location}}</a>
								</li>
								<li>
									<a href="#"><i class="fa fa-envelope-o"></i>Marketing@e-incube.com</a>
								</li>
								<li>
									<a ><i class="fa fa-phone"></i>{{item.Phone}}</a>
								</li>
								<li>
									<a href="#"><i class="fa fa-history"></i>{{item.WorkTime}}</a>
								</li>

							</ul> <!-- /.contact-list -->


        </v-card>
      </v-tab-item>
    </v-tabs-items>
							<div class="contact-social">
								<ul>
								  <li>
									  <a href="https://www.facebook.com/InCubeCo">
										  <i class="fa normal-show fa-facebook"></i>
										  <i class="fa hover-show fa-facebook"></i>
									  </a>
								  </li>
								

								  <li>
									  <a href="https://www.linkedin.com/company/incubefzco">
										  <i class="fa normal-show fa-linkedin"></i>
										  <i class="fa hover-show fa-linkedin"></i>
									  </a>
								  </li>

								  	<li>
									  <a href="https://twitter.com/INCUBEFZCO">
										  <i class="fa normal-show fa-twitter"></i>
										  <i class="fa hover-show fa-twitter"></i>
									  </a>
								  </li>


								  	<li>
									  <a href="https://www.youtube.com/channel/UCwB2XHEcS4I-3JLm9fwEC6A">
										  <i class="fa normal-show fa-youtube"></i>
										  <i class="fa hover-show fa-youtube"></i>
									  </a>
								  </li>
							  </ul>
							</div> <!-- /.contact-social -->
						</div> <!-- /.lets-contact -->
					</div> <!-- /.col- -->
					<div class="col-xs-12 col-sm-6">
						<div class="border-in">
							<div class="comm-title">
								<h3>Get in touch with us</h3>
							</div>
							<div class="contact-form" >
								<form @submit.prevent="sendEmail">
									<div class="row">
										<div class="col-xs-12 col-sm-12">
											<div class="form-group">
												<input type="text"  name="user_name" class="form-control" placeholder="Your Name" required="required" data-error="Write your name">
											</div>
										</div> <!-- /.col- -->
										<div class="col-xs-12 col-sm-12">
											<div class="form-group">
												<input type="email" name="user_email" class="form-control" placeholder="Your Email" required="required" data-error="Write your valid email address">
											</div>
										</div> <!-- /.col- -->
										<div class="col-xs-12 col-sm-12">
											<div class="form-group">
												<input type="text" name="subject" class="form-control" placeholder="Your Subject">
											</div>
										</div> <!-- /.col- -->

										<div class="col-xs-12 col-sm-12">
											<div class="form-group">
												<textarea name="message" class="form-control" placeholder="Your Message" required="required" data-error="Write your comment"></textarea>
											</div>
										</div> <!-- /.col- -->
										<div class="form-group col-xs-12">
											<div class="form-btn">
												<input type="submit" name="submit" value="Send" class="contact-form-btn">
											</div>
										</div>
										<div class="messages"></div>
									</div> <!-- /.row -->
								</form>
							</div> <!-- /.contact-form -->
						</div>
					</div> <!-- /.col- -->
				</div> <!-- /.row -->  
			</div> <!-- /.container -->
		</section> <!-- /.contact-us -->
		<!-- /Contact AREA END -->
</template>

<script >
import emailjs from 'emailjs-com';

export default {
	data () {
      return {
        tab: null,
        items: [
          {Contry : 'UAE ' , Phone : '+971-4887-6675' , Location : 'Mina Jebel Ali - Jebel Ali Freezone - Dubai - United Arab Emirates' , email : 'Info.UAE@e-incube.com' , WorkTime : '9.00 am to 6.00 pm , Monday to Friday' , Icon:'https://cdn-icons-png.flaticon.com/512/330/330534.png'} 
          , {Contry : 'Jordan ' , Phone : '+962-6520-8080' , Location : ' Ar-Reyadah St. 30, Amman' , email : 'Info.Jordan@e-incube.com' , WorkTime : '9.00 am to 6.00 pm , Sun to Thr' , Icon:'https://cdn-icons-png.flaticon.com/512/330/330608.png'}
          , {Contry : 'Palestine ' , Phone : '+970-56888-8522' , Location : 'Al Ersal ST, Palestine Trade Tower  Ramallah  Palestine' , email : 'Info.Palestine@e-incube.com' , WorkTime : '9.00 am to 6.00 pm , Sun to Thr', Icon:'https://cdn-icons-png.flaticon.com/512/330/330477.png'}
          , {Contry : 'Egypt ' , Phone : '+202 2 269-7781/2' , Location : 'Kamal Hassan Ali, Sheraton Al Matar, El Nozha, Cairo Governorate, Egypt' , email : 'Info.Egypt@e-incube.com' , WorkTime : '9.00 am to 6.00 pm , Sun to Thr', Icon:'https://cdn-icons-png.flaticon.com/512/206/206694.png'}
          ,{Contry : 'KSA ' , Phone : '+966-1-269-7959 ' , Location : 'Al Hadinah for Trading Investment Company Almughrizat, King Abdullah Bin Abdulaziz Street Building # 3776 , Riyadh 12482  KSA 6540' , email : 'Info.KSA@e-incube.com' , WorkTime : '9.00 am to 6.00 pm , Sun to Thr', Icon:'https://cdn-icons-png.flaticon.com/512/206/206719.png'}
           ,{Contry : 'South Africa' , Phone : '+27 11 243 2019 | +27 73 0296139' , Location : 'Cape town - South Africa Operations and Support HubEstablished 2020' , email : '' , WorkTime : '9.00 am to 6.00 pm , Sun to Thr', Icon:'https://cdn-icons-png.flaticon.com/512/330/330485.png'}
          ,{Contry : 'Canada ' , Phone : '+1 (905) 465-9696' , Location : '1166 South Service RD West Oakville, ON, L6L 5T7 Canada' , email : 'info.Canada@e-incube.com' , WorkTime : '9.00 am to 6.00 pm , Monday to Friday', Icon:'https://cdn-icons-png.flaticon.com/512/206/206609.png'}
        ],
      }
  },
  methods: {
    sendEmail: (e) => {
    	
      emailjs.sendForm('service_35uvwig', 'template_yzr695b', e.target, 'user_ABLqnQsG8YSY5I1R3rNi6')
        .then((result) => {
            console.log('SUCCESS!', result.status, result.text);
        }, (error) => {
            console.log('FAILED...', error);
        });
    }
  }
}
</script>