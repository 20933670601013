<template>
	<div>
	<app-header /> 
  <router-view style="background: white;"/>
  	<app-footer /> 

	  <div class="icon-bar"  v-if="$route.meta.onlyAuthUser !== true">
	<a href="https://www.facebook.com/InCubeCo" class="facebook"><i class="fa fa-facebook"></i></a>
	<a href="https://www.instagram.com/incubefzco/" class="instagram"><i class="fa fa-instagram"></i></a>
	<a href="https://www.linkedin.com/company/incubefzco" class="linkedin"><i class="fa fa-linkedin"></i></a>
	<a href="https://www.youtube.com/channel/UCwB2XHEcS4I-3JLm9fwEC6A" class="youtube"><i class="fa fa-youtube"></i></a>
  <a href="https://wa.me/message/FDX2XP3QXPXSN1" class="whatsapp"><i class="fa fa-whatsapp"></i></a>
	</div>
	</div>
</template>

<script>
		import AppHeader from '@/components/Header'
		import AppFooter from '@/components/Footer'

	
  export default {
	name: 'App',
	components:{
		AppHeader,
		AppFooter


	},


  }
</script>

<style scoped>
/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

/* Style the social media icons with color, if you want */
.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}

.instagram {
  background: #f09433; 
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}
.whatsapp {
  background: #00c50a;
  color: white;
}
</style>


