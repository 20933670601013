<template>
  <v-app>


	<div>



		<!-- Page Header AREA START -->
		<section class="page-head page-bg" style="background-image: url('assets/images/bg/about.jpg');min-height: 400px;">
			<div class="container">    
			</div> <!-- /.container -->
		</section> <!-- /.page-head -->
		<!-- /Page Header AREA END -->


		<!-- Overview AREA START -->

			<div style="margin: 50px;">
			<span v-html="AboutUS[0].About_HTML" ></span></div>
			






	</div>
  </v-app>
</template>

<script>
	
export default {
  data(){
    return{
        id :null,
    }
  },
	computed : {
		AboutUS(){
      		return this.$store.state.Info	 
		},
	},
	created(){
  	this.$store.dispatch('bindInfo')
	},
}
</script>

<style lang="scss">

.ql-direction-ltr {
  direction: ltr;
  text-align: left;
}

.ql-direction-rtl {
  direction: rtl;
  text-align: right;
}

.ql-align-center {
  text-align: center;
}
</style>