<template>
	<section class="contact-us section" >
			<div class="container" style="padding: 100px;">    
				<div class="row">
					<div class="col-xs-12 col-sm-12">
						<div class="contact-content">
							<div class="comm-title">
		<h4>{{NewsLetters[0].Title}}</h4>

              
<span v-html="NewsLetters[0].HTMLTEXT" ></span>

<div v-if="!!NewsLetters[0].PDFUrl" >
    <v-btn

                    text
                    outlined
                    color="blue lighten-2"
                    :href="`${NewsLetters[0].PDFUrl}`"
                    target="_blank"
                  >
      <v-icon
      left>mdi-file-pdf</v-icon>
      You Can Read As PDF
        
      </v-btn>
						
						</div>
							</div>
						</div> <!-- /.contact-content -->
		
					</div> <!-- /.col- -->
	
				</div> <!-- /.row -->  
			</div> <!-- /.container -->
		</section> <!-- /.contact-us -->
		<!-- /Contact AREA END -->
</template>

<script>
	
export default {
  data(){
    return{
        id :null,
    }
  },
	computed : {
		NewsLetters(){
      		return this.$store.state.NewsLetters	 
		},
	},
	created(){
		var context = this;
		this.id = this.$route.params.NewsID
  	this.$store.dispatch('bindNewsLetters',this.id).then(() => {
        if (context.NewsLetters.length == 0)
          window.location.href = "/404-page";
    });
	},
	watch: {
    '$route.params.NewsID'(newId, oldId) {
     this.id = newId
     this.$store.dispatch('bindNewsLetters',this.id)
    }
  },
}
</script>


<style lang="scss">

.ql-direction-ltr {
  direction: ltr;
  text-align: left;
  white-space: pre-wrap;
}

.ql-direction-rtl {
  direction: rtl;
  text-align: right;
  white-space: pre-wrap;
}

.ql-align-center {
  text-align: center;
  white-space: pre-wrap;
}
.ql-align-right {
  text-align: right;
 
}
p {
	white-space: pre-wrap;
}


	</style>