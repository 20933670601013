<template>
  <div style="background: white;">
    <!-- HEADER -->
    <section class="hero is-black" style="height: 200px;">
      <div class="hero-body">
        <div class="hero-img">
        </div>
        <div class="container">
          <div class="columns">
            <div class="column is-9" style="width: 60%;">

              <div class="user-tile">



              </div>
            </div>
            <div class="column is-3" style="width: 40%;">
              <div class="column-right" style="width: 40%;">

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- HEADER -->
    <div class="container">
      <v-row class="columns">
        <v-col cols="12" md="4" sm="4" lg="4">
          <div >
            <div class="card ">
              <div class="card-image" style="direction: ltr;">
                <figure class="image is-4by2">
                  <template>
                    <div v-if="Item[0].Images.length === 1">
                      <div class="slide" v-for="(i, index) in Item[0].Images" :key="index">
                        <v-img :src="Item[0].Images[0]" contain aspect-ratio="1">
                        </v-img>
                      </div>
                    </div>
                    <agile v-else :initial-slide="3">
                      <div class="slide" v-for="(i, index) in Item[0].Images" :key="index">
                        <img :src="i" :alt="i" contain aspect-ratio="1">
                      </div>

                    </agile>
                  </template>
                </figure>
              </div>
              <div class="card-content">
                <div class="content m-b-sm">
                  <div class="media-content">
                    <!-- <span class="title is-2">${{exchange.value}} /
                        </span>
                        <span class="rate" v-if="exchange.type==='product'">Day</span>
                        <span class="rate" v-else>Hour</span> -->

                    <!-- <span class="rate">Hour</span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="8"  sm="8"  lg="8">
          <div>
            <div class="section">
              <div class="more-details">
                <div class="more-details-title">
                  {{ Item[0].ItemName }}
                </div>
                <div>


                  <div>


                    <div class="more-details-item">
                      <h4>Category : </h4>
                      <div id="categoryID">
                        {{ getcategoryName(Item[0].type) }}
                      </div>
                    </div>

                    <div class="more-details-item">
                      <h4>Brand : </h4>
                      <img id="Dimage" max-height="30" max-width="250" :src="getUserImageInfo(Item[0].D_ID)"
                        style="height: 30px;">


                    </div>
                    <div class="more-details-item">
                      <v-btn text outlined color="blue lighten-2" :href="`${Item[0].PDFUrl}`" target="_blank">
                        <v-icon left>mdi-file-pdf</v-icon>
                        Download Datasheet

                      </v-btn>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="section product-description p-t-none">
              <div class="product-description-title">Description</div>
              <div class="product-description-details" style="width: 80%;">
                <p>{{ Item[0].Description }}</p>
              </div>


            </div>
          </div>
        </v-col>



      </v-row >
    </div>
  </div>
</template>

<script >
import { db } from '@/db'

export default {
  data() {
    return {
      form: {
        id: null,
        categoryName: '',
      }

    }
  },
  computed: {
    Item() {
      var item = this.$store.state.Item

      return item

    },
    // relationads()
    // {

    //   return this.$store.state.exchange.RelationAds
    // }
  },
  created() {
    this.form.id = this.$route.params.productsID
    
    this.$store.dispatch('binditem', this.form.id)
    getcategoryName(Item[0].type)


  },
  watch: {
    '$route.params.id'(newId, oldId) {
      
      this.form.id = newId
      this.$store.dispatch('binditem', this.form.id)
      getcategoryName(Item[0].type)
    }
  },
  methods: {
    format_date: function (value) {

      let myDate = new Date(value.seconds * 1000)
      var now = new Date()


      if (!(myDate.getDate() == now.getDate() && myDate.getMonth() == now.getMonth()
        && myDate.getFullYear() == now.getFullYear())) {
        let options = {
          weekday: "long", year: "numeric", month: "short", day: "numeric"
        };
        return myDate.toLocaleDateString("en-us", options)
      }
      else {
        let options = {
          hour: "2-digit", minute: "2-digit"
        };
        return ("Today , " + myDate.toLocaleTimeString("en-us", options))
      }



    },

    getcategoryName: async function (categoryID) {
      var context = this;
      await db.collection('Categories')
        .doc(categoryID)
        .get()
        .then(async snapshot => {
          let x = await snapshot.data().C_Name
          document.getElementById("categoryID").innerHTML = x
        })
    },
    getUserImageInfo: async function (DistributionID) {
      await db.collection('Distribution')
        .doc(DistributionID)
        .get()
        .then(async snapshot => {
          let x = await snapshot.data().Logo
          document.getElementById("Dimage").src = x

        })
    }
  }
}
</script>

<style scoped lang="scss">
// CARD
.card {
  z-index: 9999;
  min-width: 330px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 1px 1px rgba(20, 23, 28, .1), 0 3px 1px 0 rgba(20, 23, 28, .1);

  .title {
    color: gray;
  }

  .subtitle {
    color: gray;
  }

  .product-features {
    font-size: 17px;
  }

  .main-price {
    font-size: 17px;
    color: #7d7d7d;
    text-decoration: line-through;
  }
}

// CARD
// WHOLE HEADER
.hero-body {
  position: relative;
}

.hero-img {
  opacity: 0.8;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  filter: sepia(.1) grayscale(.1) saturate(.8);
}

.column-right {
  position: absolute;
}

@media screen and (max-width: 770px) {
  .column-right {
    position: inherit;
  }
}

.user-avatar {
  display: inline-block;
}

.is-black {
  background-color: black;
  background: linear-gradient(#29303B, #29303B, #29303B);
}

.title {
  font-weight: bold;
  font-size: 45px;
}

.subtitle {
  /*font-weight: bold;*/
  font-size: 25px;
}

.author-name {
  font-size: 20px;
  font-weight: bold;
}

.rate {
  font-size: 29px;
  font-style: italic;
}

// WHOLE HEADER END
.more-details {
  background-color: #f9f9f9;
  border: 1px solid #dedfe0;
  padding: 10px 15px;

  &-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &-items {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 19px;
    width: 45%;
  }
}

.comment {
  background-color: #f9f9f9;
  border: 1px solid #dedfe0;
  padding: 10px 15px;

  &-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &-items {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 19px;
    width: 45%;
  }
}

.product-description {
  padding-top: 0;

  &-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &-details {
    font-size: 18px;

    ul {
      list-style: disc;
      margin-left: 20px;
    }

    ol {
      margin-left: 20px;
    }

    strong {
      font-size: 20px;
    }

    p {
      min-height: 30px;
    }
  }
}

// USERS
.user-tile {
  display: flex;

  &-author {
    align-self: center;
    margin-left: 10px;

    &-name {
      font-size: 17px;
    }
  }

  .date {
    font-size: 14px;
  }
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 50%;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 80px;
}

.agile__nav-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.agile__nav-button--prev {
  left: 0;
}

.agile__nav-button--next {
  right: 0;
}

.agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.agile__dot {
  margin: 0 10px;
}

.agile__dot button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 10px;
}

.agile__dot--current button,
.agile__dot:hover button {
  background-color: #fff;
}

.slide {
  display: block;
  height: 380px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}</style> 