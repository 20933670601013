<template>
	    <header class='clearfix header' data-offset-top='60' data-spy='affix' style="margin-top: -12px;" v-if="$route.meta.onlyAuthUser !== true">

      <!-- Start  Logo & Menu  -->
      <div class='navbar navbar-default navbar-top'>
        <div class='container'>
          <div class="navbar-header">
						<!-- Stat Toggle Nav Link For Mobiles -->
						<button title="foo" type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
							<i class="fa fa-bars"></i>
						</button>
						<!-- End Toggle Nav Link For Mobiles -->
						<div class="logo">
              <a href="/"  class="navbar-brand">
                <v-img 
               max-height="300"
               max-width="200"
               alt='Logo' :src="require('@/assets/images/logo.svg')">
                </v-img>
							</a>
						</div> <!-- /.logo -->
					</div>

          <div class='navbar-collapse collapse'>
            <!-- Start Navigation List -->
            <ul class='nav navbar-nav navbar-right'>
            
              <li>
                <a>Solutions</a>
                <ul class='dropdown'>
                  <li>
                    <a >Supply Chain Solutions</a>
                    
                    <ul>
                      <div  v-for="n in Solutions" v-bind:key="n.id">
                      <li v-if="n.Type === 'SupplyChain'" >
                      <router-link
                      :to="'/Products/'+ n.SolutionTitle">
                      {{n.SolutionTitle}}
                    </router-link>
                  </li> 
                  </div>
                    </ul>
                  </li>
                  <li>
                    <a >Traceability</a>
                    
                    <ul>
                    <div  v-for="n in Solutions" v-bind:key="n.id">
                    <li  v-if="n.Type === 'Traceability'" >
                      <router-link
                      :to="'/Products/'+ n.SolutionTitle">
                      {{n.SolutionTitle}}
                    </router-link>
                  </li> 
                </div>
                      </ul>
                  </li>
                  <li>
                    <a >Solutions In a Cube</a>
                    
                    <ul>
                    <div  v-for="n in Solutions" v-bind:key="n.id">
                    <li v-if="n.Type === 'SolutionsInaCube'" >
                      <router-link
                      
                      :to="'/Products/'+ n.SolutionTitle">
                      {{n.SolutionTitle}}
                    </router-link>
                  </li> 
                </div>
                    </ul>
                  </li>
                  </ul>
              </li>
              
                <li>
                <a >Products</a>
                <ul class='dropdown'>
                  <li  v-for="(l,index) in CategoriesHead" v-bind:key="index">
                    <a>{{l}}</a>
                    
                    <ul class="sup-dropdown"  style="column-count: 2; width: 250px;">
                      <div v-for="n in Category" v-bind:key="n.id">
                      <li  v-if="n.C_Category === l" >
                      <router-link
                     
                      :to="'/Product/'+ n.C_Name">
                      {{n.C_Name}}
                    </router-link>
                  </li> 
                  </div>
                    </ul>
                  </li>


                  </ul>
                  </li>
                  
                  <li>
                <a >Partners</a>
                <ul class='dropdown' style="
                  column-count: 2;
                  width: 250px;">
                  <li v-for="n in Distribution" v-bind:key="n.id">
                    <a  :href="'/Partner/'+ n.D_Name">
                    {{n.D_Name}}

    </a>
    </li>
                  <!-- <li><a href='blog.html'>DATALOGIC</a></li>
                  <li><a href='blog-left-sidebar.html'>OMNITRACS</a></li>
                  <li><a href='single.html'>PROFFISSI</a></li>
                  <li><a href='single-left-sidebar.html'>SOTI</a></li>
                  <li><a href='single-left-sidebar.html'>HERE</a></li> -->

                </ul>
                  </li>
                  <li>
                <a >Resources</a>
                <ul class='dropdown'>
                  <li><router-link  to='/aboutus'>About</router-link ></li>
                  <li><router-link  to='/Careers'>Careers</router-link ></li>
                  <li><router-link  to='/Events'>Events</router-link ></li>
                  <li><router-link  to='/AllNewsletter'>News</router-link ></li> 
                    <li><router-link  to='/IIC'>INCUBE Innovation Center</router-link ></li>
                  <li>
                    <router-link  to='/Whistleblower'>Whistleblower</router-link >
                  </li>
                  <li><router-link  to='/ContactUs'>Contact Us</router-link ></li>                 
                  </ul>
              </li>
              <li>
                <a >Opportunities</a>
                <ul class='dropdown'>
                  <li><router-link  to='/Opportunities/Reseller' >Become a Reseller</router-link></li>
                  <li><router-link  to='/Opportunities/Partner'>become a Partner</router-link></li>
                  <li><router-link  to='/Opportunities/Demo'>Request a Demo</router-link></li>

                </ul>
              </li>
                      </ul>
            <!-- End Navigation List -->
          </div>
          <!-- /.navbar-collapse -->
        </div> <!-- /.container -->

        <!-- Mobile Menu Start -->
        <ul class='wpb-mobile-menu'>
          <li><a class='active' href='index.html'>Home</a></li>
          <li>
                <a >Solutions</a>
                <ul class='dropdown'>
                  <li><a href='index.html'>Supply Chain Solutions</a></li>
                  <li>
                <ul class='dropdown'>
                  <li><a href='index.html'>SONIC</a></li>
                  <li><a href='about.html'>SONIC TT</a></li>
                  <li><a href='services.html'>SONIC Meat </a></li>
                  <li><a href='blog.html'>PING</a></li>
                  <li><a href='contact.html'>VOLUME</a></li>
                  <li><a href='team.html'>INTRACK</a></li>
                  <li><a href='portfolio.html'>ECHO</a></li>
                </ul>
              </li>
                  <li><a href='about.html'>BLOCKCHAIN Solutions</a></li>
                  <ul class='dropdown'>
                  <li><a href='index.html'>WAVE</a></li>
                  </ul>
                  <li><a href='services.html'>Solutions In A Cube</a></li>
                  <ul class='dropdown'>
                  <li><a href='index.html'>SONIC</a></li>
                  <li><a href='about.html'>SONIC TT</a></li>
                  <li><a href='services.html'>SONIC Meat </a></li>
                  <li><a href='blog.html'>PING</a></li>
                  <li><a href='contact.html'>VOLUME</a></li>
                  <li><a href='team.html'>INTRACK</a></li>
                  <li><a href='portfolio.html'>ECHO</a></li>
                  
                </ul>
                </ul>
              </li>
              <li>
                <a>Products</a>
                <ul class='dropdown'>
                <li><a href='index.html'>Printing Solutions</a></li>
                <ul class='dropdown'>
                  <li><a href='about.html'>Thermal Desktop</a></li>
                  <li><a href='services.html'>Dot Matrix Mobile</a></li>
                  <li><a href='blog.html'>Dot Matrix Desktop</a></li>
                </ul>
                              </ul>
              </li>
                <li><a href='index.html'>Scanning Solutions</a></li>
                  <li>
                <ul class='dropdown' style="column-count: 2; width: 250px;">
                  <li v-for="n in Category" v-bind:key="n.id" >
                    <a v-if="n.C_Category === 'Printing Solutions'">Fixed Retail</a></li>

                </ul>
                  </li>
                
              <li>
                <a href='blog.html'>Partners</a>
                <ul class='dropdown' v-for="n in Distribution" v-bind:key="n.id">
                  <li><a href='blog2.html'>{n.D_Name}</a></li>
                </ul>
              </li>
              <li>
                <a href='blog.html'>Resources</a>
                <ul class='dropdown'>
                  <li><a href='blog2.html'>About INCUBE Mobility Solutions</a></li>
                  <li><a href='blog.html'>Careers</a></li>
                  <li><a href='blog-left-sidebar.html'>Events</a></li>
                  <li><a href='/AllNewsletter'>News</a></li>
                  <li><a href='single-left-sidebar.html'>Contact Us</a></li>
                                    <li><a href='index.html'>INCUBE Academy</a></li>
                  <li>
                <ul class='dropdown'>
                  <li><a href='index.html'>The Logistics Institute</a></li>
                  <li><a href='about.html'>Supply Chain and Future of Tech Summit</a></li>
                </ul>
                                  </li>
                </ul>
              </li>
              <li>
                <a href='blog.html'>Opportunities</a>
                <ul class='dropdown'>
                  <li><a href='blog2.html'>Become a Reseller</a></li>
                  <li><a href='blog.html'>become a Partner</a></li>
                  <li><a href='blog-left-sidebar.html'>Request a Demo</a></li>

                </ul>
              </li>
        </ul> <!-- /.wpb-mobile-menu -->
      </div> <!-- /.navbar -->
      <!-- End Header Logo & Naviagtion -->
    
    </header> <!-- /.header -->
</template>

<script>

  import { mapMutations, mapState } from 'vuex'

export default {
  name: 'app',
  data () {
      return {
        CategoriesHead : [],
      }
    },
    created(){
      this.partnerID = this.$route.params.PartnerID
      this.$store.dispatch('bindAllDistribution',this.partnerID)
      this.$store.dispatch('bindCategories');
      this.$store.dispatch('bindacademies');
      this.$store.dispatch('bindSolutions');
    },
    computed:{
      Distribution(){
        return this.$store.state.AllDistribution
      },
      Category(){
        let cate = this.$store.state.Categories
        this.fillCategories(cate)
        return cate
      },
      academies(){
        return this.$store.state.academies
      },
      Solutions(){
        return this.$store.state.Solutions
      }
    },
    methods :{
      fillCategories(ArrObj){
        this.CategoriesHead = []
          for (var x = 0 ; x < ArrObj.length ; x++){
            if (this.CategoriesHead.includes(ArrObj[x].C_Category)===false)
            {
              this.CategoriesHead.push(ArrObj[x].C_Category)
            }
          }
      }
    }
}
</script>
