<template>
	<div>

		
<div>
<hero />
</div>


	</div>
</template>


<script>
	import hero from '@/components/IncubeMain'


	import { mapMutations, mapState } from 'vuex'

export default {
	name: 'app',
	data () {
      return {
        dialog: false,
      }
    },
	components:{
		hero

	},
	 methods: { 
  ...mapMutations({
     setdialogstate: 'Set_DialogState',
    }),
	},


}
</script>




