import Vue from 'vue'
import HomePage from '@/views/pages/home'
import LoginPage from '@/views/pages/Login'
import Products from '@/views/pages/products/Products_Page'
import Partner from '@/views/pages/Partners/Partner'
import NotFound from '@/views/pages/NotFound'
import ContactUs from '@/views/pages/ContactUs'
import ProductDetails from '@/views/pages/ProductDetails'
import CareerDetails from '@/views/pages/Career/CareerDetails'
import Router from 'vue-router'
import Careers from '@/views/pages/Careers'
import Events from '@/views/pages/Events'
import ProductsbyCategory from '@/views/pages/products/Products_by_Category'
import AboutUs from '@/views/pages/Aboutus'
import user from './store'
import Opportunities from '@/views/pages/Opportunities'
import Whistleblower from '@/views/pages/Whistleblower'
import survey from '@/views/pages/survey'
import Academy from '@/views/pages/Academy'
import IIC from '@/views/pages/IIC'
import Privacy from '@/views/pages/Privacy'
import NewsLetter from '@/views/pages/NewsLetter'
import AllNewsLetter from '@/views/pages/AllNewsletter'
import LivePage from '@/views/pages/Live'
Vue.use(Router)


const router = new Router({
	mode: 'history',
	base: process.env.PUBLIC_URL,
	routes: [
		{
			path: '/Dashboard',
			component: () => import('@/views/dashboard/Index'),
			meta: { onlyAuthUser: true },
			children: [
				// Dashboard
				{
					name: 'Dashboard',
					path: '/Dashboard',
					component: () => import('@/views/dashboard/Dashboard'),
					meta: { onlyAuthUser: true },
				},
				// Pages
				{
					name: 'Solutions',
					path: '/Dashboard/Solutions',
					component: () => import('@/views/dashboard/pages/Solutions'),
					meta: { onlyAuthUser: true },
				},
				{
					name: 'Defines',
					path: '/components/Defines',
					component: () => import('@/views/dashboard/component/Defines'),
					meta: { onlyAuthUser: true },
				},
				{
					name: 'Events',
					path: '/components/Events',
					component: () => import('@/views/dashboard/component/Events'),
					meta: { onlyAuthUser: true },
				},
				{
					name: 'Career',
					path: '/components/Career',
					component: () => import('@/views/dashboard/component/Career'),
					meta: { onlyAuthUser: true },
				},
				// Tables
				{
					name: 'Items',
					path: '/tables/MainItem',
					component: () => import('@/views/dashboard/tables/MainItem'),
					meta: { onlyAuthUser: true },
				},
				{
					name: 'Settings',
					path: '/components/Settings',
					component: () => import('@/views/dashboard/component/Settings'),
					meta: { onlyAuthUser: true },
				},
				{
					name: 'IIC',
					path: '/components/IIC',
					component: () => import('@/views/dashboard/component/IIC'),
					meta: { onlyAuthUser: true },
				},
				{
					name: 'NewsLetter',
					path: '/components/NewsLetter',
					component: () => import('@/views/dashboard/pages/NewsLetter'),
					meta: { onlyAuthUser: true },
				},
				{
					name: 'Portal',
					path: '/components/Portal',
					component: () => import('@/views/dashboard/component/Portal'),
					meta: { onlyAuthUser: true },
				},
				{
					name: 'Academy',
					path: '/components/Academy',
					component: () => import('@/views/dashboard/component/Academy'),
					meta: { onlyAuthUser: true },
				},
				{
					name: 'Partner',
					path: '/components/Partner',
					component: () => import('@/views/dashboard/component/Partner'),
					meta: { onlyAuthUser: true },
				},
			],

		},
		{
			path: '/',
			name: 'HomePage',
			component: HomePage,
		},
		{
			path: '/login',
			name: 'loginPage',
			component: LoginPage,
		},
		{
			path: '/products/:ProductID',
			name: 'products',
			component: Products,
		},

		{
			path: '/Partner/:PartnerID',
			name: 'Partner',
			component: Partner,
		},
		{
			path: '/Academy/:AcademyID',
			name: 'Academy',
			component: Academy,
		},
		{
			path: '/ContactUs',
			name: 'Contact Us',
			component: ContactUs,
		},
		{
			path: '/ProductDetails/:productsID',
			name: 'Product Details',
			component: ProductDetails,
		},
		{
			path: '/Product/:CategoryID',
			name: 'Products Category',
			component: ProductsbyCategory,
		},
		{
			path: '/Careers',
			name: 'Careers',
			component: Careers,
		},
		{
			path: '/AboutUs',
			name: 'AboutUs',
			component: AboutUs,
		},
		{
			path: '/Events',
			name: 'Events',
			component: Events,
		},
		{
			path: '/Live',
			name: 'Live',
			component: LivePage,
		},
		{
			path: '/IIC',
			name: 'InCube Innovation Center',
			component: IIC,
		},
		{
			path: '/privacy-policy',
			name: 'Privacy Policy',
			component: Privacy,
		},
		{
			path: '/CareerDetails/:CareerID',
			name: 'Career Details',
			component: CareerDetails,
		},
		{
			path: '/Opportunities/:Type',
			name: 'Opportunities',
			component: Opportunities,
		},
		{
			path: '/Whistleblower',
			name: 'Whistleblower',
			component: Whistleblower,
		},
		{
			path: '/NewsLetter/:NewsID',
			name: 'NewsLetter',
			component: NewsLetter,
		},
		{
			path: '/survey',
			name: 'survey',
			component: survey,
		},
		{
			path: '/AllNewsLetter',
			name: 'News Letter',
			component: AllNewsLetter,
		},
		{
			path: "*",
			name: 'Not Found',
			component: NotFound
		}
	],
	runGuardsAndResolvers: 'always',
})

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0)
	var l = user
	if (to.meta.onlyAuthUser) {
		if (user.state.user) {
			next()
		} else {
			//TODO Navigate to page for non authentecated users
			next({ name: 'loginPage' })
		}
	} else {
		next()
		if (to.path.split('/')[1] == '')
			document.title = 'INCUBE FZCO | The Hub Of Supply Chain'
		else
			document.title = 'INCUBE FZCO | ' + to.path.split('/')[to.path.split('/').length - 1];
	}


})



export default router