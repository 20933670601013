<template>
  <v-app>
<div id="container" style="
    width: 60%;
    height: 60%;
"> 
  <div id="monitor">
    <div id="monitorscreen">
        <video-player  class="vjs-custom-skin"
                 ref="videoPlayer"
                 :options="playerOptions"
                 @ready="playerReadied"
>
  </video-player>
    </div>
  </div>
</div>
</v-app>
</template>


<script>
  import 'video.js/dist/video-js.css'
  import { videoPlayer } from 'vue-video-player'

    export default {
    components: {
      videoPlayer
    },
    data() {
      return {
 playerOptions: {
          height: '392',
          width : '700',
          playbackRates: [0.7, 1, 1.3, 1.5, 1.7],
          sources: [{
            type: "video/mp4",
             src: "http://vjs.zencdn.net/v/oceans.mp4"
          }],
          poster: "https://surmon-china.github.io/vue-quill-editor/static/images/surmon-3.jpg",
        }
      }
    },
    mounted() {
      console.log('this is current player instance object', this.player)
    },
    computed: {
      player() {
        return this.$refs.videoPlayer.player
      }
    },
    methods: {
         playerReadied(player) {
        var hls = player.tech({ IWillNotUseThisInPlugins: true }).hls
        player.tech_.hls.xhr.beforeRequest = function(options) {
          // console.log(options)
          return options
        }
    }
  }
}
</script>
<style lang="scss">
body {
  background-color: #222;
}

#container {
  max-width: 1024px;
  margin: auto;
}

#monitor {
  background: #000; 
  position: relative;
  border-top: 3px solid #888; 
  margin: 5%;
  padding: 2% 2% 4% 2%; 
  border-radius: 10px; 
  border-bottom-left-radius: 50% 2%; 
  border-bottom-right-radius: 50% 2%; 
  transition: margin-right 1s;
}

#monitor:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 3%;
  left: 36%;
  height: .5%; 
  width: 28%;
  background: #ddd; 
  border-radius: 50%; 
  box-shadow: 0 0 3px 0 white; 
}

#monitorscreen {
  position: relative;
  background-color: #777;
  background-size: cover; 
  background-position: top center;
  height: 0; 
  padding-bottom: 56.25%; 
  position: relative;
  overflow: hidden;
}


@media all and (min-width: 960px) {
  #monitor {
    -webkit-animation: tvflicker .2s infinite alternate; 
    -moz-animation:    tvflicker .5s infinite alternate; 
    -o-animation:      tvflicker .5s infinite alternate; 
    animation:         tvflicker .5s infinite alternate; 
  }

  @-webkit-keyframes tvflicker {
    0%   { box-shadow: 0 0 100px 0 rgba(200,235,255,0.4); }
    100% { box-shadow: 0 0 95px 0 rgba(200,230,255,0.45); }
  }
  @-moz-keyframes tvflicker {
    0%   { box-shadow: 0 0 100px 0 rgba(225,235,255,0.4); }
    100% { box-shadow: 0 0 60px 0 rgba(200,220,255,0.6); }
  }
  @-o-keyframes tvflicker {
    0%   { box-shadow: 0 0 100px 0 rgba(225,235,255,0.4); }
    100% { box-shadow: 0 0 60px 0 rgba(200,220,255,0.6); }
  }
  @keyframes tvflicker {
    0%   { box-shadow: 0 0 100px 0 rgba(225,235,255,0.4); }
    100% { box-shadow: 0 0 60px 0 rgba(200,220,255,0.6); }
  }
}
</style>


