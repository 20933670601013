<template>
  <v-app>
    <div>
      <center>
        <div class="section product-description p-t-none">

          <div class="comment" style="width: 80%;margin-top: 10%;">



            <h3 style="margin-bottom: 50px;">
              <center>Available Jobs ({{ Careers.length }})</center>
            </h3>


            <template>

              <div v-if="!Careers.length">


                <v-img max-height="150" max-width="250"
                  src="https://firebasestorage.googleapis.com/v0/b/incubewebsite.appspot.com/o/Others%2Fempty.png?alt=media&token=9cf2fc13-4302-415e-a486-31fd3df0ea3e"></v-img>

                <p style="font-size: x-large;">Sorry, there aren't any new careers. Please check back later.</p>


              </div>
              <div v-else>
                <v-card margin="5px" class="mx-auto" style="width: 100%;margin: 5px;" v-for="n in Careers"
                  v-bind:key="n.id">
                  <v-list-item>
                    <figure class="image is-64x64">
                      <img class="is-rounded" :src="require('@/assets/icon.png')"
                        style="margin-top: 10px;height: 80%;width: 80%;">
                    </figure>
                    <v-list-item-content>

                      <h2 class="text-left">{{ n.JobName }}</h2>
                      <h4 class="text-left"
                        style="color: black;display: -webkit-box;-webkit-line-clamp: 4;-webkit-box-orient: vertical;overflow: hidden;">
                        {{ n.description }}
                      </h4>
                    </v-list-item-content>
                  </v-list-item>


                  <v-card-actions>
                    <v-btn class="ma-2" color="indigo" outlined style="margin: 5px;">
                      {{ (n.afterInterview) ? 'TBD' : n.Salary }}
                      <v-icon color="indigo" right>
                        mdi-currency-usd
                      </v-icon>
                    </v-btn>
                    <v-btn class="ma-2" color="indigo" outlined style="margin: 5px;">
                      {{ n.location }}
                      <v-icon color="indigo" right>
                        mdi-google-maps
                      </v-icon>
                    </v-btn>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ma-2" color="indigo" outlined style="margin: 5px;">
                          {{ n.Experince }}
                          <v-icon color="indigo" right>
                            mdi-brain
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Experience</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>


                    <v-btn outlined color="indigo" :to="'/CareerDetails/' + n.ID">
                      Apply This Job
                      <v-icon right dark>
                        mdi-cloud-upload
                      </v-icon>
                    </v-btn>

                  </v-card-actions>
                </v-card>
              </div>



            </template>

          </div>
        </div>
      </center>
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'app',
  data() {
    return {
      dialog: false,
    }
  },
  created() {
    this.$store.dispatch('bindCareers');
  },

  computed: {
    Careers() {
      return this.$store.state.Careers
    },
  },

}
</script>