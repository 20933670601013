import * as firebase from 'firebase'





// Get a Firestore instance

//Canada Config
// var config = { 
//   apiKey: "AIzaSyAhC7znMwplak2wY_ZBoax22Wn4BtoL_HE",
//   authDomain: "incubewebsite.firebaseapp.com",
//   databaseURL: "https://incubewebsite-default-rtdb.firebaseio.com",
//   projectId: "incubewebsite",
//   storageBucket: "incubewebsite.appspot.com",
//   messagingSenderId: "507998210257",
//   appId: "1:507998210257:web:f41ad2a70bf89f7d6d1df0"
// };

//FZCO Config
var config = {
  apiKey: "AIzaSyCrqVMHoc5kZzXslr3lvqT5LQ9YPN6cHmY",
  authDomain: "websiteincubefzco.firebaseapp.com",
  projectId: "websiteincubefzco",
  storageBucket: "websiteincubefzco.appspot.com",
  messagingSenderId: "1006497790240",
  appId: "1:1006497790240:web:2c6858dce5746b4f1eff45"
};

export const fb = firebase.initializeApp(config)

export const db = firebase.firestore()

export const userAuth = null






// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
// const { TimeStamp, GeoPoint } = firebase.firestore()
// export { TimeStamp, GeoPoint }
