<template>
    <v-app>
        <div
            style="background-image: url(https://firebasestorage.googleapis.com/v0/b/websiteincubefzco.appspot.com/o/Others%2Fsurvey.png?alt=media&token=2ed5aa63-3313-4004-98ab-5f16c96007ea;)">
            <section class="page-head page-bg" style="background: rgb(0 0 0 / 45%); min-height: 720px;">
                <div class="col-sm-8 col-xs-12 col-sm-offset-2 col-xs-offset-0">
                    <center>
                        <h1 style="font-family: Cairo;">
                            Customer Satisfaction<br><br>رضا الزبائن
                            <br>
                        </h1>
                    </center>
                    <div style="height: 20px;">
                    </div>


                    <v-row justify="center" align="center">
                        <v-col cols="auto">
                            <v-btn color="#5865f2" density="compact"
                                href="https://forms.office.com/r/JPTVbwB6nP?origin=lprLink"
                                style="font-family: Cairo;font-size: large;">English</v-btn>
                        </v-col>

                        <v-col cols="auto">
                            <v-btn color="#5865f2" density="comfortable"
                                href="https://forms.office.com/r/GkJxATu1SB?origin=lprLink"
                                style="font-family: Cairo;font-size: large;">العربية</v-btn>
                        </v-col>
                    </v-row>

                </div>
            </section> <!-- /.page-head -->
            <!-- /Page Header AREA END -->

            <!-- Our Services AREA START -->

            <!-- /Our Services AREA END -->
        </div>
    </v-app>
</template>
<script>

export default {
    name: 'app',
    data() {
        return {
        }
    },
    created() {


    },
    computed: {

    },
    watch: {

    },
    methods: {

    }

}
</script>
  
<style lang="sass" scoped>
  .v-card.on-hover.theme--dark
    background-color: rgba(#FFF, 0.8)
    >.v-card__text
      color: #000
  </style>