<template>
	<div >
		<center>

			    <!-- Recent Blog AREA START -->
    <section class='recent-blog section'>
      <div class='container'>    
        <div class='row'>
          <div class='col-sm-8 col-xs-12 col-sm-offset-2 col-xs-offset-0'>
            <div class='section-heading'>
              <h2 class='title'><span>Our</span> Recent Newsletter</h2>
              <p>Check out our updates, our partners latest products and more!</p>
            </div>
          </div>
        </div> <!-- /row -->
        <div class='row'>
          <div class='col-sm-6 col-md-4'  v-for="n in NewsLettersBanr" v-bind:key="n.id">
             
            <div class='re-blog-item'>
              <div class='re-feature-img'>
                <v-img alt='Features' :src="n.Image" height="200" aspect-ratio="1"/>
              </div>
              <div class='caption'>
                <a :href="'/NewsLetter/'+ n.ID"><h4 class='gen-title'>{{n.Title}}</h4></a>
                <p style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">{{n.SubTitle}}</p>
              </div>
               <router-link
                      :to="'/NewsLetter/'+ n.ID">
              <div class='re-time'>
               {{ format_date(n.Date)}}
              </div>
            </router-link>
            </div> <!-- /.re-blog-item -->
          
          </div> <!-- /.col- -->
     
    
        </div> <!-- /.row -->  
      </div> <!-- /.container -->
    </section> <!-- /.recent-blog -->

		</center>
	</div>

</template>

<script>

export default {
  computed: {
    NewsLettersBanr(){
      return this.$store.state.NewsLettersBanr
    },

  },
  methods:{
          format_date : function(value){
       
            let myDate = new Date(value.seconds * 1000)
            var now = new Date()

          
            if (!(myDate.getDate() == now.getDate() && myDate.getMonth() == now.getMonth() 
              && myDate.getFullYear() == now.getFullYear()) )
             { 
              let options = {   
                   weekday: "long", year: "numeric", month: "short",  day: "numeric" 
              };  
              return myDate.toLocaleDateString("en-us", options)
              }
            else
            {
              let options = {   
                hour: "2-digit", minute: "2-digit"  
              };  
              return ("Today , " + myDate.toLocaleTimeString("en-us", options))
            }
            
           
          
      },
  },
  created(){
    this.$store.dispatch('bindAllNewsLetters');
  },

}
</script>