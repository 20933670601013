/* eslint-disable */
<template>

 <div id="app" style="padding: 100px;">
  <v-app :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }" :dark="darkTheme" id="inspire">
    <v-container>
      <v-row >
        <v-col sm="12" md="6" offset-md="3">
          <v-card elevation="4" light tag="section">
            <v-card-title>
              <v-row align="center" justify="space-between">
                <h3 class="headline" style="margin: 20px;">
                  {{ form.platformName }}
                </h3>
                <v-col>
                  <v-row>
                  <v-img :alt="platformName" class="ml-3" contain height="48px" position="center right" :src="require('@/assets/icon.png')"></v-img>
                </v-row>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <p>Sign in with your username and password:</p>
              <v-form>
                <v-text-field
                              outline
                              label="Username"
                              type="text"
                              v-model="form.username"></v-text-field>
                <v-text-field
                              outline
                              hide-details
                              label="Password"
                              type="password"
                              v-on:keyup.enter="onEnter" 
                              v-model="form.password"></v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }">

              <v-spacer></v-spacer>
              <v-btn 
              @click="handleLogin"
              color="blue-grey" flat>
                Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</div>
  
</template>

<script>
import AppHeader from '@/components/Header'
import { db,fb  } from '@/db'
import { mapMutations, mapState } from 'vuex'
export default {
  data() {
    return {
      form: {
       snackbar: false,
       username: '',
       password: '',
       snakMsg : 'error',
      darkTheme: true,
      platformName: 'InCube Dashboard',
      }
    }
  },
  methods: {
      ...mapMutations({
        setUser: 'Set_User',
        setusertype : 'Set_UserType',
      }),
    onEnter: function() {
      this.handleLogin();
    },
    handleLogin() {
    
    var data1 = db
      .collection('Users')
      .where('UserName' , '==' , this.form.username.toLowerCase())
      .get()
      .then(snapshots => {
        
        if(snapshots.docs.length > 0) {
            const user =snapshots.docs[0];
            if (user.data().Password == this.form.password){
              var type = user.data().Type
              this.setUser(this.form.username )
              this.setusertype(type)

              this.$router.push('/Dashboard')
           
             
            }else{
              this.form.snakMsg = 'Wrong Password !!'
              this.form.snackbar = true;
               
            }
        }else{
              this.form.snakMsg = 'User Not Found !!'
              this.form.snackbar = true;
             
        }
      })
    
    return
    }
  }
}
</script>

<style lang="scss">

  
</style>


<style scoped >
  .hero.is-success {
    background: #F2F6FA;
  }
  .hero .nav, .hero.is-success .nav {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .box {
    margin-top: 5rem;
  }
  .avatar {
    margin-top: -70px;
    padding-bottom: 20px;
  }
  .avatar img {
    padding: 5px;
    background: #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
    box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
  }
  input {
    font-weight: 300;
  }
  p {
    font-weight: 700;
  }
  p.subtitle {
    padding-top: 1rem;
  }
.v-btn,
.v-card {
  border-radius: 4px;
}
.v-card__title {
  text-transform: uppercase;
}
</style>